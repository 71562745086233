.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .2s ease-in-out;

  .input-item {
    input {
      transition: all .3 ease-in-out;
      border: 1px solid transparent;
      box-shadow: 0 0 4px transparent;

      &:hover,
      &:focus,
      &:active {
        border: 1px solid $custom-purple;
        box-shadow: 0 0 4px $custom-purple;
      }
    }
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media (max-width: $large-mobile-max) {
  .ReactModal__Content {
    width: 90% !important;
  }
  .subscribe {
    p {
      box-sizing: border-box;
      max-width: 400px;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
