@import './mixins.scss';

.footer {
  box-sizing: border-box;
  width: 100%;
  min-height: 520px;
  background: #141414;
  display: flex;
  align-items: center;
  color: #fafafa;
  padding-bottom: 80px;
  box-shadow: 0px 4px 9px black;
  position: absolute;
  z-index: -101;

  @media (max-width: $large-mobile-min) {
    display: block;
  }

  @media (max-width: $medium-mobile-min) {
    display: block;
  }

  &-container {
    align-items: center;
    bottom: 45px;
    box-sizing: border-box;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: -100;

    @media (max-width: $large-mobile-min) {
      display: block;
      padding: 70px 80px 55px;
    }
  }

  h4 {
    font-size: 2.8em;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin-top: 36px;
    margin-bottom: 46px;
    font-family: Sentinel-Book;
    line-height: 1;

    @media (max-width: $large-desktop-min) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    @media (max-width: $large-mobile-min) {
      margin-top: 16px;
      margin-bottom: 0px;
      font-size: 19px;
    }
  }

  a {
    color: #fafafa;
    line-height: 1.15;
    font-size: 1.4em;
    font-family: MaisonNeue-Book;
  }

  select {
    font-size: 1.1em;
    background-color: transparent;
    color: #fafafa;
    height: 38px;
    width: 178px;
    padding-left: 16px;
    border: 0;
    outline: 1px inset white;
    outline-offset: -1px;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .footer-logo {
    width: 39.7%;
    text-align: center;
    margin-top: 85px;

    img {
      width: 54px;
      height: 100px;
      margin: auto;
      text-align: center;

      @media (max-width: $large-desktop-min) {
        width: 48px;
        height: 91px;
      }

      @media (max-width: $large-mobile-min) {
        width: 35px;
        height: 65px;
      }
    }

    @media (max-width: $large-mobile-min) {
      width: fit-content;
    }
  }

  .sections-container {
    width: 61.25%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    @media (max-width: $pad-min) {
      width: 115%;
    }

    @media (max-width: $large-mobile-min) {
      margin-top: 0;
    }

    li {
      text-align: left;

      @media (max-width: $large-mobile-min) {
        padding-left: 20px;
      }
    }

    > div {
      margin-bottom: -6px;
      font-family: 'Lato';

      @media(max-width: $large-mobile-min) {
        margin-bottom: 1px;
      }

      &.about-section {
        width: 37.3%;

        @media (max-width: $large-mobile-min) {
          width: 100%;
          order: 1;
        }
      }

      &.policies-section {
        width: 37.7%;

        @media (max-width: $large-mobile-min) {
          width: 100%;
          order: 3;
        }
      }

      &.social-section {
        width: 23%;

        @media (max-width: $large-mobile-min) {
          width: 100%;
          order: 4;
        }
      }

      &.contact-section {
        width: 37.3%;

        @media (max-width: $large-mobile-min) {
          width: 100%;
          order: 2;
        }

        .footer-link-list {
          font-family: MaisonNeue;
          font-size: 1.4em;

          li {
            margin-bottom: 5px;

            a {
              font-size: 1em;
            }
          }

          .phone {
            margin-top: 30px;
          }

          select {
            margin-bottom: 15px;
          }
        }
      }

      &.international-section {
        @media (max-width: $large-mobile-min) {
          order: 5;
          padding-right: 15px;
        }
      }

      ul {
        list-style-type: none;
        text-align: left;

        .footer-icon {
          font-size: 14px;

          &:hover {
            color: rgb(103, 103, 103);
          }
        }

        i {
          margin-right: 10px;
        }
      }
    }

    h4.international {
      @media (max-width: $large-mobile-min) {
        display: none;
      }
    }

    .dropdown {
      display: flex;
      text-align: left;
      width: 100%;

      @media (max-width: $large-mobile-min) {
        width: 100%;

        &:not(:last-child) {
          border-bottom: 1px solid $dark-gray;
        }

        h4 {
          width: 100%;
        }
      }

      i {
        padding-top: 18px;
        padding-bottom: 18px;
        margin-left: auto;
        font-size: 19px;
      }

      .mobile {
        display: none;

        @media (max-width: $large-mobile-min) {
          display: block;
        }
      }
    }
  }

  @media (max-width: $large-mobile-min) {
    flex-direction: column;
    height: auto;

    .footer-logo {
      margin: 0;
      margin-bottom: 20px;
    }

    .sections-container {
      width: 100%;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;

      div {
        width: 50%;

        ul {
          text-align: center;
        }
      }
    }

    h4 {
      text-align: left;
    }
  }

  .footer-link-list {

    @media (max-width: $large-mobile-min) {
      &.international {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 20px;

        .custom-dropdown {
          width: 77px;
          margin-left: 30px;
        }
      }

      &.show {
        display: unset;
        text-align: left;
      }

      &.hide {
        display: none;
      }
    }

    li {
      margin-bottom: 5px;

      @media (max-width: $large-mobile-min) {
        margin-top: 15px;
      }

      .selected {
        font-size: 0.78em;
      }
    }

    .custom-dropdown {
      margin: 0;

      .dropdown-container {
        margin: 0;
        height: 44px;

        @media (max-width: $large-desktop-min) {
          height: 40px;
        }

        .selection.dropdown {
          background-color: $custom-black;
          color: #fff;
          border: 1px solid white;
          width: 177px;
          height: 34px;
          margin: 0;
          padding: 0 10px;
          min-height: 30px;
          transition: background-color .2s;

          i {
            margin-right: -20px;
          }

          &:hover {
            color: $custom-black;
            background-color: #dcdcdc;
          }

          &.active {
            border: 1px solid #fff;

            .text {
              color: #fff;
            }

            &:hover {
              .text {
                color: $custom-black;
              }
            }

            .menu {
              > div {
                padding: 6px 16px !important;

                .text {
                  color: black;
                  font-size: 10px;
                }

                &:hover {
                  background-color: #dcdcdc;
                }
              }

            }
          }

          .text {
            margin-bottom: 0;
            font-family: MaisonNeue-Demi;
            font-size: 0.78em;
          }

          @media (max-width: $large-desktop-min) {
            height: 30px;
          }

          @media (max-width: $large-mobile-min) {
            border: none;
            padding: 0;
            width: 100px;

            &.active {
              border: none;
            }

            .text {
              font-size: 12px;
              text-align: right;
            }

            i {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.portfolio-link {
  color: rgb(5, 112, 152);

  &:hover {
    text-decoration: underline;
  }
}
