@import './mixins.scss';

.multi-keyword-search-component {
  width: 100%;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;

  .component-title {
    margin-bottom: 30px;
    font-family: MaisonNeue-Book;
    font-size: 16px;    
  }

  .component-body {
    width: 100%;
    display: flex;

    @media (max-width: $small-desktop-min) {
      display: block;
      width: 360px;
    }

    @media (max-width: $pad-min) {
      display: block;
      width: 100%;
    }
    
    .input-area {
      position: relative;
      margin-right: 90px;
      
      img.icon-search {
        position: absolute;
        width: 48px;
        left: 7px;
        top: 7px;
      }

      input {
        padding-left: 50px;
        border: 1px solid $light-gray;
        font-family: MaisonNeue-Book;
        font-size: 12px;
        width: 310px;
        height: 60px;
        margin-bottom: 10px;

        @media (max-width: $large-mobile-min) {
          width: 100%;
        }
      }
    }
  
    .keywords-container {
      display: flex;
      flex-wrap: wrap;
  
      .keyword-item {
        display: flex;
        min-width: 110px;
        height: 30px;
        margin-right: 15px;
        margin-bottom: 7px;
        background: $custom-black;
        color: white;
        align-items: center;
        
        p {
          font-family: MaisonNeue-Book;
          font-size: 12px;
          flex: 1;
          margin-left: 15px;
          margin-right: 10px;
        }
  
        i {
          font-size: 12px;
          margin-right: 15px;
          padding-bottom: 7px;
        }
      }
    }
  }
}