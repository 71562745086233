@import './mixins.scss';

.mobile-background {
  position: absolute;
  top: 0;
  background: $custom-black;

  @media (max-width: $large-mobile-min) {
    width: 100%;
    height: 150px;
    z-index: -1;
  }
}

.search-page {
  background-color: #fff;

  @media (max-width: $large-mobile-min) {
    padding: 0px;
  }

  .header-page {
    background-image: url("./assets/book-a-home-header.jpg");
    background-position: center right;
    height: auto;
    padding: 181px 0 220px;

    @media (max-width: $large-desktop-min) {
      padding: 80px 0 120px;
      height: 270px;
    }

    &-btn {
      padding: 22px 60px 20px;
      bottom: -25px;

      @media (max-width: $pad-max) {
        height: 50px;
        padding: 22px 40px 20px;
      }

      @media (max-width: $large-mobile-min) {
        width: 190px;
        padding: 20px 0px;
      }
    }
  }

  &-copy {
    width: 25%;
    margin: 0 auto;
    padding-top: 100px;

    @media (max-width: $large-mobile-min) {
      width: 190px;
    }

    .description {
      width: 50%;
      margin-bottom: 70px;

      @media (max-width: $small-desktop-min) {
        width: 44%;
      }

      @media (max-width: $pad-min) {
        width: 100%;
        margin-bottom: 50px;
      }

      @media (max-width: $large-mobile-min) {
        margin-bottom: 30px;
      }

      p {
        width: 300px;
        font-family: Quarto-Light;
        font-size: 4em;

        @media (max-width: $large-mobile-min) {
          width: 200px;
          font-size: 30px;
          text-align: left;
          margin-left: 0;
        }
      }
    }

    .header {
      margin-bottom: 70px;

      h3 {
        font-family: MaisonNeue-Demi;
        text-transform: uppercase;
        margin-bottom: 25px;
        font-size: 1.6em;
      }

      p {
        width: 250px;
        font-family: Sentinel-BookItalic;
        font-size: 1.6em;

        @media (max-width: $smobile-min) {
          width: 280px;
        }
      }
    }
  }
}

.form-container.searchJ15 {
  width: 1080px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $small-desktop-min) {
    width: 860px;
  }

  @media (max-width: $pad-min) {
    width: 720px;
  }

  @media (max-width: $large-mobile-min) {
    width: 360px;
  }

  @media (max-width: $medium-mobile-min) {
    width: 320px;
  }

  @media (max-width: $smobile-min) {
    width: 280px;
  }
}

.form-container.searchJ15 {
  padding-bottom: 115px;

  input[type=checkbox] {
    -ms-transform: scale(1.5);
    /* IE */
    -moz-transform: scale(1.5);
    /* FF */
    -webkit-transform: scale(1.5);
    /* Safari and Chrome */
    -o-transform: scale(1.5);
    /* Opera */
    padding: 10px;
  }

  .j15filter-container .filter-bar {
    .search-box {
      height: 60px;

      @media (max-width: $large-desktop-min) {
        height: 50px;
      }

      li {
        .icon {
          height: 40px;

          &.people {
            height: 34px;
          }
        }

        i.icon {
          height: 100%;
        }

        &.destination {
          .default {
            padding-right: 10px;
          }
        }
      }

      @media (max-width: $large-mobile-min) {
        height: auto;

        li {
          height: 50px;
        }
      }

      input,
      .ui.selection.dropdown,
      select {
        font-size: 1.6em;
      }

      input {
        padding-top: 3px;
      }
    }

    .filter-button {
      font-size: 1em;

      img {
        width: 18%;
        padding-left: 27px;
        padding-right: 10px;
      }

      @media (max-width: $large-desktop-min) {
        height: 50px;
      }

      span {
        font-size: 1.6em
      }
    }
  }

  .row-container {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: $large-mobile-min) {
      margin-top: 10px;
    }
  }

  .full-width {
    width: 100%;
  }

  .form-section {

    @media (max-width: $pad-min) {
      margin-left: auto;
      margin-right: auto;
    }

    .details {
      width: 450px;

      @media (max-width: $large-mobile-min) {
        width: 360px;
      }

      @media (max-width: $medium-mobile-min) {
        width: 320px;
      }

      @media (max-width: $smobile-min) {
        width: 280px;
      }

      .row-container {
        flex-wrap: nowrap;
      }

      .input-render-wraper {

        &.first-name,
        &.last-name {
          margin-left: 15px;

          @media (max-width: $large-mobile-min) {
            margin-left: 10px;
          }
        }

        input {
          background: #eeeff0;
          font-family: MaisonNeue-Book;
          font-size: 1.6em;

          @media (max-width: $large-mobile-min) {
            font-size: 12px;
          }

          @media (max-width: $smobile-min) {
            font-size: 10px;
          }
        }
      }

      .dropdown-container {
        width: 90px;

        .dropdown {
          background: #eeeff0;

          i {
            background: transparent;
          }
        }
      }

      .submit {
        height: 60px;
        text-align: center;
        background-color: #141414;
        color: white;
        font-size: 1.4em;
        font-family: MaisonNeue-Demi;
        cursor: pointer;

        @media (max-width: $large-desktop-min) {
          height: 50px;
        }
      }
    }
  }
}
