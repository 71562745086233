
@import './mixins.scss';

.listing-detail-page {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .visible {
    display: block !important;
  }

  .listing-picture {
    display: block;
    width: 100%;
    min-height: 500px;
    height: 500px;
    z-index: -10;
    object-fit: cover;
  }

  .swiper-container {
    height: ($R * 70vw);
  }

  @media (max-width: $pad-min) {
    .swiper-container {
      width: 100%;
      height: 500px;
    }

    .listing-detail-body {
      width: calc(100% - 80px);
      margin: 0 auto;
  
      .booking-box-container {
        display: none;
      }

      .mobile-button {
        display: flex;

        &.show-booking {
          background-color: #242424;
          color: white;
        }
      }
    }
  }

  @media (max-width: $medium-mobile-max) {
    .listing-main-info .row-container {
      flex-direction: column;
    }

    .swiper-container {
      height: 350px;
    }

    .listing-section-header-links {
      padding-left: 0px;
      
      ul {
        flex-direction: column;
      }
    }

    .related-listings {
      height: 200px;
    }
  }

  @media (max-width: $sm-max) {
    .listing-detail-body {
      .booking-box-container {
        .DateRangePicker_picker {
          .DayPicker__horizontal {
            width: 300px !important;
          }

          .DayPicker_weekHeader_1 {
            &:not(:first-child) {
              display: none;
            }
          }

          .DayPicker_transitionContainer {
            height: 600px !important;
            width: 300px !important;

            .CalendarMonthGrid__horizontal {
              width: 300px !important;

              .CalendarMonth {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $medium-mobile-min) {
    .our-promise {
      height: auto;

      .promises {
        flex-direction: column;
        align-content: center;
      }
    }

    .related-listings .listings a .related-listing-detail h2 {
      font-size: 14px;
    }

    .check-info {
      flex-direction: column;
      height: auto !important;
    }
  }
}

.related-listings {
  width: calc(100% - 100px);
  height: 350px;
  margin: 40px auto;
  padding-bottom: 50px;

  p {
    text-align: center;
    font-weight: 600;
  }

  .listings {
    display: flex;
    height: 100%;

    a {
      width: 50%;
      height: 100%;
      cursor: pointer;
      color: white;

      &:hover {
        opacity: 0.7;
      }

      .related-listing-detail {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          font-size: 36px;
          font-weight: 400;
          margin-top: 0;
        }

        span {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }
}

.listing-section-header-links {
  padding: 0;
  padding-left: 13%;

  ul {
    display: flex;
    li {
      font-size: 18px;
      margin-left: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      
      &:hover {
        cursor: pointer;
      }

      a {
        color: #ccc;
      }
    }
  }
}

.listing-detail-body {
  max-width: 700px;
  padding: 20px 0 30px 0;
  margin-left: 15%;
}

.listing-detail-head {
  display: flex;
  flex-direction: row;
}

.listing-host-info {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listing-main-info {
  width: 700px;

  .row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 12px;
      font-weight: 400;
      text-decoration: underline;
      color: black;
    }
  }
}

.booking-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 5%;
  z-index: 2;

  @media(max-width: $large-mobile-max) {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    align-items: center;
    margin-left: 10px;

    .mobile-button {
      min-width: 325px;
    }
  }

  .mobile-button {
    float: right;
    display: none;
    justify-content: center;
    align-items: center;
    width: 70px;
    text-align: center;
    height: 50px;
    background-color: #e4e4e4;
    cursor: pointer;
  }

  .booking-box-container {
    background-color: #e4e4e4;
    width: 325px;

    .price-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
      color: white;
      font-size: 18px;
      box-sizing: border-box;
      opacity: 0.9;
      width: 100%;
      color: black;
      height: 120px;

      .day-price {
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 0px;
      }

      .week-price {
        font-size: 14px;
        font-weight: 300;
      }
    }

    .booking-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    
      p {
        font-size: 10px;
        color: rgb(118, 118, 118);
        padding-top: 20px;
        padding-bottom: 10px;
      }

      .book-button {
        color: white;
        background: black;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        height: 50px;
        font-size: 12px;
      
        &:hover {
          cursor: pointer;
          background: #242424;
        }
      }

      .guests-dropbox {
        width: 286px;
        height: 52px;
        margin-top: 20px;
        background-color: white;

        i {
          font-size: 20px;
          color: #888888;
          margin: auto 10px;
        }

        select {
          width: 80%;
          height: 100%;
          color: #888888;
          border: none;
          background-color: white;
        }
      }
    }

    .assistance-box {
      background-color: white;
      color: #888888;
      padding-top: 10px;
      width: 100%;

      div {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.question {
          border: none;
          font-size: 18px;
        }

        &.contact {
          border: 1px solid #e4e4e4;

          &:last-child {
            border-top: none;
          }

          i {
            font-size: 24px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.listing-detail-name {
  font-size: 32px;
  font-weight: 400;
  color: black;
}

.subinfo {
  color: black;
  font-size: 14px;
  padding: 10px 0;
}

.listing-main-attributes {
  display: flex;
  justify-content: space-between;
}

.attribute-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80px;

  p {
    margin-left: 10px;
  }

  i {
    font-size: 18px;
  }
}

.book-button:hover {
  cursor: pointer;
}

.listing-detail-description {
  margin: 10px auto;

  .check-info {
    height: 40px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;

    span {
      font-size: 12px;
      font-weight: 600;
    }

    i {
      font-size: 20px;
    }
  }

  .highlights, .amenities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #ccc;

    h1 {
      text-align: center;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 15px;
      width: 100%;
    }

    .checked-item {
      width: 30%;
      margin-bottom: 10px;
    }
  }
}

.extras {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px 30px;

  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 15px;
    width: 100%;
  }

  .extra-item {
    display:  flex;
    justify-content: space-between;
    align-items: center;

    .title {
      width: 66.6%;
    }

    .price {
      width: 33.3%;
    }
  }
}

.service-ad {
  background-image: url(assets/about_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  
  .enjoy-service {
    width: 300px;
    padding: 30px 10px;
    background-color: rgba(220, 220, 220, 0.8);
    text-align: center;

    h1 {
      font-size: 28px;
      font-weight: 400;
    }

    p {
      font-size: 18px;
      font-weight: 300;
    }
  }
}

.our-promise {
  border: 1px solid black;
  height: 400px;
  margin: 50px 10px 10px 10px;
  padding: 50px;

  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
    width: 100%;
  }

  .promises {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;

    .row {
      display: flex;
      justify-content: space-around;
      flex: 1;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100px;

        i {
          font-size: 80px;
        }

        span {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
}

.floor-plan {
  margin-top: 60px;
  padding-top: 20px;
  padding-bottom: 50px;
  border-top: 1px solid #ccc;

  h1:first-child {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
    width: 100%;
  }

  img {
    width: 100%;
    padding: 10px;
    height: 700px;
  }
}

.location {
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #ccc;

  h1:first-child {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
    width: 100%;
  }

  .google-map {
    width: 100%;
    height: 400px;
  }
}

.policies {
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #ccc;

  h1:first-child {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
    width: 100%;
  }

  .police-items {
    margin-top: 30px;
    padding-bottom: 30px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }

  a {
    font-size: 18px;
    font-weight: 300;
    color: black;
  }
}

.listing-description-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px;

  p {
    color: black;
    font-size: 13px;
  }
}

.listing-section-header {
  width: 60%;

  padding: 30px 40px 20px 40px;

  ul {
    list-style: none;
  }

  h3 {
    color: black;
    font-size: 15px;
    margin: 0 0 16px 0
  }

  li {
    color: black;
    font-size: 13px;
  }
}

.host-pic {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
