@import './style/_mixins.scss';
@import './style/_reset.scss';
@import './style/_slider.scss';
@import './style/_listing_detail.scss';
@import './style/_booking_form_container.scss';
@import './style/_global.scss';
@import './style/_listing.scss';
@import './style/_event_calendar.scss';
@import './style/_image_slider.scss';
@import './style/_fonts.scss';
@import './style/_searchJ15.scss';
@import './style/_tab_style.scss';
@import './style/_search_multi_keyword.scss';
@import './style/_svg_file.scss';
@import './style/_calendar.scss';
@import './style/_modals.scss';
html {
  height: auto;
  min-height: 100%;
}
body {
  background-color: #141414;
  font-size: 10px;
  height: auto;

  @media (max-width: $large-desktop-max) {
    font-size: 8px;
  }

  @media (max-width: $small-desktop-min) {
    font-size: 6px;
  }

  @media (max-width: $large-mobile-min) {
    font-size: 10px;
  }

  // Modal responsiveness styles
  @media (max-width: $medium-mobile-max) {
    .google-link {
      @media (max-width: 514px) {
        width: 320px;
      }

      @media (max-width: 410px) {
        width: 260px;
      }
    }

    .signup-modal-wrapper {
      width: calc(100% - 90px);

        .input-item {
          flex-direction: column;

          .user-name {
            margin-right: 0 !important;
          }

          .image-uploader {
            margin-right: 0 !important;

            > div {
              margin-right: 0 !important;
            }
          }
        }
      }

      .login-modal-wrapper {
        width: calc(100% - 90px);

        .login-form-box {
          width: 100%;
        }

        .input-fields-box {
          flex-direction: column;
          width: 100%;

          .input-item {
            padding: 0;
            margin-right: 0 !important;

            .login-input {
              width: calc(100% - 4rem) !important;
            }
          }
        }

        .links {
          flex-direction: column;

          a {
            padding: 5px 0 !important;
          }
        }
      }

      .image-cropper-wrapper {
        width: calc(100% - 100px) !important;
      }
    }

  &.protected-page {
    background-color: #f1f1f1;
  }

  img {
    object-fit: cover;
  }

  .ui.centered.divided {
    &.pupup-container {
      display: flex;
      flex-direction: column;

      .center.aligned.column {
        display: flex;
        align-items: center;
        width: calc(100% - 2rem);

        &:hover {
          background-color: #eeeeee;
        }

        div {
          margin-right: 20px;
          width: 50px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          .date {
            margin: auto;
          }

          &.not-available {
            background-color: #242424;
            color: white;
          }

          &.might-available {
            background-color: #ccccca;
            color: black;
          }

          &.available {
            background-color: #f0f0f0;
            color: black;
          }

          &.booked {
            background-color: #f0f0f0;
            color: black;

            .diagonal-line {
              width: 50px;
              height: 30px;
              border-bottom: 2px solid black;
              transform: translateY(-12px) translateX(-7px) rotate(-30deg);
              -webkit-transform: translateY(-12px) translateX(-7px) rotate(-30deg);
              position: absolute;
              z-index: 2;
            }
          }
        }

        .header {
          margin-top: 0;
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: black;
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-input:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.earnings {
  svg {
    width: 100%
  }
}

.ReactModalPortal {
  > div {
    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.75) !important;
    }
  }
}

i.icon.dropdown:before {
  content: '\F078' !important;
  font: normal normal normal 12px/1 FontAwesome;
  right: 20px;
  position: absolute;
}

.row {
  display: flex;
}

button {
  &.slick-arrow {
    z-index: 999;
    background-color: transparent;
  }
}

.slick-prev {
  left: 25px !important;
}
.slick-next {
  right: 53px !important;
}

.slick-prev:before, .slick-next:before {
  font-size: 30px;
}

.checklist-items {
  .checkbox-row {
    label {
      font-size: 12px !important;
      padding-top: 2px;
      padding-left: 24px;
    }
  }
}

.search-box {
  .guests {
    .ui.selection.dropdown.guest .menu>.item {
      padding: 10px !important;
    }
  }
}
