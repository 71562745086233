html, body, header, nav, h1, a, ul, li,
strong, main, section, img, div, h2, p,
form, fieldset, label, input, textarea,
button, article, footer, small {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  box-sizing: inherit;
  text-align: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
  color: inherit;
  background: transparent;
}
