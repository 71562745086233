.swiper-container {
  width: 100%;
  height: 100%;

  .swiper-pagination {
    .swiper-pagination-bullet {
      background: black;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: white;
    }
  }

  .swiper-button-next {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23ffffff'/></svg>");
    background-position-x: calc(100% - 30px);
    right: 0;
  }

  .swiper-button-prev {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23ffffff'/></svg>");
    background-position-x: 30px;
    left: 0;
  }

  .swiper-button-next, .swiper-button-prev {
    height: 100%;
    top: 0;
    margin-top: 0;
    width: calc(15vw - 9px);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .home-slider-el {
    height: 100%;
    width: calc(70vw);
    position: relative;
  
    .home-slider-pic {
      cursor: pointer;
      width: 100%;
      object-fit: cover;
    }
  
    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 80%;
      color: white;
      font-size: 2rem;
    }
  }
}
