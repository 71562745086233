.calendar {
  background-color: #eeeff0;
  display: flex;
  justify-content: center;
  padding: 40px 0;

  &__switcher {
    display: flex;
    flex-direction: column;
    margin-right: 150px;
    width: 200px;
  }

  &__switcher-box {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  &__switcher-date {
    font-family: Sentinel;
    font-size: 21px;
  }

  &__switcher-arrow {
    cursor: pointer;
    transform: scale(1.5);
  }

  &__switcher-months {
    display: inline;
  }

  &__switcher-month {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-family: 'Maison Neue - Demi', sans-serif;
    font-size: 13px;
    justify-content: center;
    padding: 20px 0;
    text-transform: uppercase;
    width: 50%;

    &:hover {
      background-color: #d4d4d4;
    }

    &--selected {
      color: #fff;

      &,
      &:hover {
        background-color: #000;
      }
    }
  }

  &__info-container {
    margin-left: 150px;
  }

  &__info-box {
    margin-bottom: 40px;
    min-width: 250px;
  }

  &__info-title {
    display: block;
    font-family: 'Maison Neue - Book', sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  &__info-pair {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.calendar-widget {
  background-color: #efefee;
  box-shadow: 0 0 27px 5px rgba(#000, .071);
  width: 450px;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 28px;
  }

  &__heading {
    font-family: Sentinel;
    font-size: 21px;
    font-weight: 500;
    padding: 0 120px;
  }

  &__header-arrow {
    cursor: pointer;
    transform: scale(1.5);
  }

  &__weekdays {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
  }

  &__weekday {
    color: #cdcccc;
    font-family: 'Maison Neue', sans-serif;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__days {
    display: inline;
  }

  &__day {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-family: 'Maison Neue - Demi', sans-serif;
    font-size: 13px;
    font-weight: 400;
    height: 65px;
    justify-content: center;
    width: calc(100% / 7);

    &--inactive {
      color: #999;
    }

    &:hover {
      background-color: #d4d4d4;
    }
  }
}
