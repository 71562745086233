.listing {
  .slick-dots li  {
    margin: 0;

    button:before {
      font-size: 10px;
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin: 0;
  }

  .react-datepicker {
    background-color: #eeeff0;

    &-wrapper,
    &__input-container {
      width: 100%;
    }

    &__header {
      border: 0;
    }

    &__month {
      margin: 0;
    }

    &__current-month {
      padding: 10px;
    }

    &__current-month,
    .react-datepicker-time__header {
      font-family: Sentinel;
      font-size: 21px;
    }

    &__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      border-radius: 0;
      margin: 0;
      padding: 15px;

      &:hover {
        background-color: #cdccca;
        color: #000;
      }
    }

    &__day-name {
      color: #cdcccc;
    }

    &__day--selected {
      background-color: #000;
    }

    &__navigation {
      height: 1px;
      top: 22px;
      width: 1px;
    }

    &__navigation--previous {
      border-right-color: #000;
    }

    &__navigation--next {
      border-left-color: #000;
    }

    &__triangle {
      display: none;
    }
  }

  &__main-image {
    display: block;
  }

  &__photos {
    background-color: #fff;
    border: 2px solid #000;
    height: 80%;
    left: 0;
    margin: 0 auto;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    width: 70%;
    z-index: 10;
  }

  &__photos-picture {
    margin: 10px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__photos-listing {
    padding: 20px;
  }

  &__photos-overlay {
    background-color: #000;
    height: 100%;
    opacity: .5;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  &__photos-close {
    border: 1px solid #000;
    cursor: pointer;
    padding: 3px 5px 4px;
    position: absolute;
    right: 40px;
    top: 40px;
    transform: scale(2);
    transition: background-color .5s, color .5s;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  &__gallery {
    position: relative;

    .slick-dots {
      bottom: 30px;
    }
  }

  &__arrow-block {
    align-items: center;
    background-color: #646464;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: .3;
    position: absolute;
    top: 0;
    transition: opacity .3s;
    width: 70px;
    z-index: 1;

    &:hover {
      opacity: 1;
    }

    i {
      color: #fff;
      transform: scale(2.5);
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__view-gallery {
    cursor: pointer;
    display: block;
    font-family: 'Maison Neue - Demi';
    font-size: 12px;
    font-weight: 400;
    margin: 20px 0;
    text-align: center;
    text-transform: uppercase;
  }

  &__container {
    display: flex;
    margin: 60px auto auto;
    max-width: 1680px;

    &--no-flex {
      display: block;
    }

    &--full {
      max-width: none;
      width: 100%;
    }
  }

  &__booking-column {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    width: 30%;
  }

  &__booking-box {
    background-color: #141414;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px;
  }

  &__booking-price {
    color: #fff;
    font-family: Sentinel;
    font-size: 24px;
    margin-bottom: 30px;
  }

  &__booking-button {
    background-color: #fff;
    color: #141414;
    cursor: pointer;
    font-family: 'Maison Neue - Demi';
    font-size: 14px;
    font-weight: 400;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  &__booking-picker {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 20px 0;

    &:hover {
      background-color: rgba(#5e5e5e, .3);
    }

    &--with-borders {
      border-top: 1px solid #292929;
      border-bottom: 1px solid #292929;
    }
  }

  &__booking-picker-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__booking-picker-icon {
    height: 35px;
    width: 35px;
  }

  &__booking-picker-label {
    color: #fff;
    font-family: 'Maison Neue - Book';
    font-size: 16px;
    margin-left: 20px;
  }

  &__booking-assistance {
    font-family: 'Sentinel - Book';
    font-size: 21px;
    margin-top: 30px;
    text-align: center;
  }

  &__booking-actions-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__booking-action {
    align-items: center;
    border: 1px solid #cdccca;
    cursor: pointer;
    display: flex;
    font-family: 'Maison Neue - Book';
    font-size: 16px;
    height: 60px;
    justify-content: center;
    transition: background-color .3s;
    width: 210px;

    &:hover {
      background-color: rgba(#cdccca, .5);
    }
  }

  &__info-column {
    padding: 0 80px;
    width: 70%;
  }

  &__info-title {
    font-family: Quarto;
    font-size: 55px;
  }

  &__info-title-box {
    display: flex;
    justify-content: space-between;
  }

  &__info-title-icon {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    margin: 0 20px;

    img {
      height: 40px;
      width: 40px;
    }

    span {
      font-family: 'Maison Neue';
      font-size: 16px;
    }
  }

  &__info-location {
    display: block;
    font-family: 'Maison Neue';
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  &__info-description {
    font-family: Sentinel;
    font-size: 24px;
    margin: 40px 0;
  }

  &__concierge {
    align-items: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }

  &__concierge-icon {
    height: 40px;
    width: 40px;
  }

  &__concierge-info {
    display: inline-flex;
    font-family: 'Maison Neue - Demi';
    font-size: 18px;
    font-weight: 400;
    margin: 0 10px;
    text-transform: uppercase;
  }

  &__features {
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
  }

  &__features-container {
    display: flex;
    justify-content: space-between;
  }

  &__feature {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__feature-items {
    margin-top: 20px;
  }

  &__feature-item {
    display: inline-flex;
    font-family: 'Maison Neue - Book';
    font-size: 16px;
    margin-top: 20px;
    width: 25%;
  }

  &__feature-image {
    height: 55px;
    width: 55px;
  }

  &__feature-label {
    font-family: 'Maison Neue - Book';
    font-size: 16px;
  }

  &__section {
    margin: 60px auto 0;
  }

  &__section-title {
    display: block;
    font-family: 'Maison Neue - Demi';
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  &__extras {
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;

    .listing__extras-item:nth-child(odd) {
      margin-right: 5%;
    }

    .listing__extras-item:nth-child(even) {
      margin-left: 5%;
    }
  }

  &__extras-item {
    display: inline-flex;
    font-family: 'Maison Neue - Demi';
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    margin-bottom: 20px;
    text-transform: uppercase;
    width: 45%;
  }

  &__extras-parking {
    align-items: center;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    padding: 30px 0;
  }

  &__extras-parking-box {
    display: flex;

    &--label {
      justify-content: space-between;
      width: 45%;
    }

    &--message {
      justify-content: flex-end;
    }
  }

  &__extras-parking-info {
    color: #ccc;
    font-family: 'Maison Neue';
    font-size: 16px;
    text-transform: uppercase;

    &--visible {
      color: #000;
    }
  }

  &__extras-parking-message {
    font-family: 'Maison Neue - Book';
    font-size: 14px;
  }

  &__comments {
    margin: 80px 0;
    text-align: center;

    .slick-dots {
      bottom: 0;
    }
  }

  &__comments-title {
    display: block;
    font-family: Quarto;
    font-size: 55px;
    margin: 0 auto 50px;
    max-width: 500px;
    text-align: center;
  }

  &__comment {
    max-width: 700px;
  }

  &__comment-content {
    font-family: 'Sentinel - Book Italic';
    font-size: 20px;
    text-align: center;
  }

  &__comment-additional-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__comment-info {
    font-family: "Maison Neue - Book";
    font-size: 14px;

    &--exposed {
      font-family: "Maison Neue - Demi";
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__comments-arrow {
    cursor: pointer;
    position: absolute;
    top: 30px;
    transform: scale(2);
    z-index: 1;

    &--right {
      right: 20%;
    }

    &--left {
      left: 20%;
    }
  }

  &__related {
    margin: 50px auto 100px;
  }

  &__related-title {
    display: block;
    font-family: 'Maison Neue - Demi';
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  &__related-container {
    display: flex;
  }

  &__related-box {
    position: relative;
    width: 50%;
  }

  &__related-image {
    width: 100%;
  }

  &__related-label {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1;

    &--price {
      font-family: 'Maison Neue - Demi';
      font-size: 20px;
      text-transform: uppercase;
      top: 35%;
    }

    &--name {
      font-family: 'Sentinel - Book';
      font-size: 40px;
      top: calc(50% - 20px);
    }
  }

  &__banner {
    width: 100%;
  }

  &__expander {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    transition: background-color .3s;
    width: 100%;

    &:hover {
      background-color: rgba(#e4e4e4, .5);
    }
  }

  &__expander-container {
    border-bottom: 1px solid #ccc;
    min-width: 700px;
    padding-bottom: 30px;
  }

  &__expander-icon {
    transform: rotate(90deg) scale(1.5);

    &--expanded {
      transform: rotate(-90deg) scale(1.5);
    }
  }

  &__policies-block {
    display: flex;
    margin-bottom: 70px;
  }

  &__policies-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;

    &--wider {
      width: 60%;
    }
  }

  &__policies-button {
    border: 1px solid #cdccca;
    cursor: pointer;
    font-family: 'Maison Neue - Demi';
    font-size: 14px;
    outline: 0;
    padding: 20px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .3s;
    width: 200px;

    &:hover {
      background-color: rgba(#cdccca, .5);
    }
  }

  &__policies-category {
    font-family: 'Sentinel - Book';
    font-size: 34px;
  }

  &__policies-line {
    font-family: Sentinel;
    font-size: 24px;
    padding-left: 30px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

.expander {
  &-enter {
    opacity: 0.01;

    &.expander-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }
  }

  &-leave {
    opacity: 1;

    &.expander-leave-active {
      opacity: 0.01;
      transition: opacity 300ms ease-in;
    }
  }
}
