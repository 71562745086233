@font-face {
  font-family: 'MaisonNeue-Demi';
  src: url('./assets/fonts/MaisonNeue-Demi.woff2') format('woff2'),
       url('./assets/fonts/MaisonNeue-Demi.woff') format('woff'),
       url('./assets/fonts/MaisonNeue-Demi.ttf') format('truetype');
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('./assets/fonts/MaisonNeue-Medium.woff2') format('woff2'),
       url('./assets/fonts/MaisonNeue-Medium.woff') format('woff'),
       url('./assets/fonts/MaisonNeue-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MaisonNeue-Book';
  src: url('./assets/fonts/maisonneuebook.ttf') format('truetype');
}

@font-face {
  font-family: 'Sentinel-Book';
  src: url('./assets/fonts/Sentinel-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Sentinel-Light';
  src: url('./assets/fonts/Sentinel-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Sentinel-LightItalic';
  src: url('./assets/fonts/Sentinel-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Sentinel-BookItalic';
  src: url('./assets/fonts/Sentinel-BookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Quarto-Light';
  src: url('./assets/fonts/Quarto-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Quarto-LightItalic';
  src: url('./assets/fonts/Quarto-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Quarto';
  src: url('./assets/fonts/Quarto-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Quarto-MediumItalic';
  src: url('./assets/fonts/Quarto-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Quarto-Bold';
  src: url('./assets/fonts/Quarto-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Quarto-BoldItalic';
  src: url('./assets/fonts/Quarto-BoldItalic.otf') format('opentype');
}

