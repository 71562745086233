.rheostat {
  overflow: visible;
  width: 100%;
}

.rheostat-background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
  height: 3px;
}

.rheostat-progress {
  background-color: rgb(0, 166, 153);
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 90%;
  padding-left: 20px;
}

.rheostat-handle {
  background-color: #fff;
  border: 1px solid #858585;
  border-radius: 50%;
  height: 22px;
  outline: none;
  z-index: 2;
  width: 22px;
  transform: translateY(-12px);
}

.rheostat-handle:before,
.rheostat-handle:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #dadfe8;
}
