.ui.attached.tabular {
  &.menu {
    width: 100%;
    height: 60px;
    border: none;

    .item {
      border: none;
      background: #444444;
      border-radius: 0px !important;
      width: 20%;
      margin: 0;
      padding: 0;
      text-align: center;
      color: white;
      justify-content: center;
      font-size: 14px;
      font-weight: 300;
      z-index: 4;

      &.active {
        background: #141414;
      }
    }
  }
}