@import './mixins.scss';

.success-container {
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  height: 450px;
  padding: 0 80px;

  @media (max-width: $large-mobile-min) {
    padding: 0 10px;
  }

  button {
    width: 180px;
    height: 60px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $custom-black;
    font-size: 10px;
    font-family: MaisonNeu-Demi;
    text-transform: uppercase;
    margin-top: 130px;

    i {
      font-size: 20px;
      margin-right: 18px;
    }
  }

  h2 {
    font-family: Quarto-Light;
    font-size: 40px;
    padding-top: 130px;
    text-align: center;
  }

  .messages {
    margin-top: 20px;

    p {
      font-family: MaisonNeue-Demi;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
  }

}
