$smobile-min: 320px;
$smobile-max: 374px;

$medium-mobile-min: 375px;
$medium-mobile-max: 424px;

$large-mobile-min: 425px;
$large-mobile-max: 767px;

$pad-min: 768px;
$pad-max: 1023px;

$small-desktop-min: 1024px;
$small-desktop-max: 1279px;

$medium-desktop-min: 1280px;
$medium-desktop-max: 1439px;

$large-desktop-min: 1440px;
$large-desktop-max: 1599px;

$xlarge-desktop-min: 1600px;
$R: 0.5625;

$xs-max: 'screen and (max-width: 575px)';

$sm-min: 'screen and (min-width: 576px)';
$sm-max: 767px;

$md-min: 768px;
$md-max: 'screen and (max-width: 991px)';

$lg-min: 992px;
$lg-max: 1199px;

$xl-min: 1200px;
$xl-max: 1399px;

$custom-black: #141415;
$dark-gray: #303031;
$light-gray: #cdccca;
$off-white: #eeeff0;
$custom-purple: #5824a8;
$bg-gray: #efefef;