@import './mixins.scss';
$borderRadius: .3rem;
$margin: .5rem ;
$padding: .25rem .5rem;
$eventColor: #3b91ad;
$eventFontSize: 14px;

// html {
//   box-sizing: border-box;
// }
// *, *:before, *:after {
//   box-sizing: inherit;
// }

.flexContainer {
  box-sizing: border-box;
  max-width: 500px;
  height: 300px;
  border-top: 1px solid $eventColor;
  border-left: 1px solid $eventColor;
  border-color: white;
  border-width: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: $large-mobile-min) {
    max-width: 100%;
  }
}

.flexColumn {
  display: flex;
  width: 14.2857142857%;
  border-bottom: 1px solid $eventColor;
  border-right: 1px solid $eventColor;
  border-width: 2px;
  border-color: white;
  flex: 0 1 auto;
  justify-content: center;
  position: relative;

  span {
    position: absolute;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.day.inactive {
  background-color: #f8f8f8;
  color: #ccc;
}

.day.today {
  background-color: #fcf8e3;
}

.day .inner-grid {
  width: 100%;
  position:relative;
  background-color: #efefef;

  &.not-available {
    color: white;
  }

  &.might-available {
    color: black;
  }

  &.booked {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .diagonal-line {
    width: 115%;
    height: 100%;
    border-bottom: 2px solid black;
    -webkit-transform: translateY(-17px) translateX(-14px) rotate(-35deg);
    position: absolute;
    z-index: 2;
  }
}

.day .event-slot {
  position: relative;
  font-size: $eventFontSize;
  height: 100%;
}

.day .event-slot.event {
  background-color: #efefef;
  color: black;
  white-space: nowrap;
  text-indent: -10000px;
  cursor: pointer;
}

.day .event-slot.event.not-available {
  background-color: #242424;
}

.day .event-slot.event.might-available {
  background-color: #ccccca;
}

.day .event-slot.event.booked {
  background-color: transparent;
}

.day .event-slot .event-title {
  position: absolute;
  top:($eventFontSize/2)/2;
  left:$margin;
  z-index: 100;
  color: #fff;
  z-index:1;
  overflow:visible;
  text-indent: 0;
}

// .day .event.event-first-day {
//   margin-left: $margin;
//   border-top-left-radius: $borderRadius;
//   border-bottom-left-radius: $borderRadius;
// }

// .day .event.event-last-day {
//   margin-right: $margin;
//   border-top-right-radius: $borderRadius;
//   border-bottom-right-radius: $borderRadius;
// }

.day .date {
  padding: $padding;
  text-align: right;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}   