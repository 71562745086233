.cls-1 {
    fill:none;
    stroke:#010101;
    stroke-miterlimit:10;
    stroke-width:3px;
    &.white {
        fill: white;
        stroke: white;
    }
}
.cls-2 {
    fill:#010101;
    &.white {
        fill: white;
    }
}