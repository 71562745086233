@import './mixins.scss';

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 76px;
  left: 0;
  background-color: $custom-black;
  overflow-x: hidden;

  @media (min-width: $pad-min) {
    display: none;
  }
}

.navbar {
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  background: white;
  height: 70px;
  box-shadow: none;

  @media (min-width: $large-desktop-min) {
    position: fixed;
    z-index: -1;
  }

  @media (max-width: $large-desktop-min) {
    height: 55px;
  }

  @media (max-width: $large-mobile-min) {
    background: $custom-black;
  }

  &-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $large-desktop-min) {
      position: absolute;
      z-index: 0;
    }
  }

  .nav-buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 30vw;
    height: 100%;

    .user-icon {
      height: 32px;
    }
  }

  .menu-wrapper {
    display: flex;
    height: 100%;
    flex: 1;
  }
}

.nav-logo {
  background-position: left;
  background-repeat: no-repeat;
  display: block;
  width: 23vw;
  height: 28px;
  margin-left: 48px;

  @media (max-width: $large-desktop-min) {
    height: 25px;
  }

  @media (max-width: $large-mobile-min) {
    height: 24px;
  }

  @media (min-width: $large-desktop-min) {
    margin-left: 80px;
  }

  .logo-image {
    background-image: url("../assets/svg/navlogo.svg");
    background-repeat: no-repeat;
    height: 100%;

    @media (max-width: $large-mobile-min) {
      background-image: url("../assets/svg/navlogo-white.svg");
      margin-left: 20px;
    }
  }

  .fa-bars {
    display: none;
    color: white;
  }
}

.nav-search {
  display: block;
  padding: 10px 20px;
  flex-grow: 1;
  font-size: 13px;
}

.nav-buttons {
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;

  &.group.menus {
    flex: 100;
    justify-content: center;
  }

  li {
    padding: 0 1.3vw;
    font-family: MaisonNeue-Demi;
    font-size: 1.6em;
    color: #303031;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background .2s;

    &.selected {
      background-color: $off-white;
      @media (max-width: $large-mobile-min) {
        background-color: transparent;
      }
    }

    &.nav-login,
    &.nav-user-info {
      color: white;
      background-color: #303031;
      width: 5vw;
      text-align: center;
      max-width: 70px;

      i {
        font-size: 20px;
      }

      span {
        display: none;
      }

      &:hover {
        color: black;
      }
    }

    &.nav-login {
      &:hover {
        background: #3f3f41 !important;
      }
    }

    &.nav-signup {
      background-color: #efefef;
      width: 11.25vw;
      text-align: center;
      max-width: 180px;
    }

    &.nav-booking {
      background-color: #efefef;
      width: 140px;
      text-align: center;
    }

    &.nav-property {
      background-color: #efefef;
      width: 140px;
      text-align: center;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: $large-mobile-min) {
      width: auto;
    }

    &:hover {
      color: inherit;
      opacity: 1 !important;
    }
  }

  button {
    cursor: pointer;
  }

  > li:hover {
    background: rgb(245, 245, 245);
  }
}

.nav-logo:hover {
  opacity: 0.8;
  cursor: pointer;
}

.profile-dropdown {
  display: none;
  list-style: none;
  border: 1px solid #eee;
  z-index: 100;
}

.dropdown-logout:hover .nav-logout {
  margin-left: 5px;
}

.nav-user-info {
  z-index: 1000;
}

.nav-user-info:hover .profile-dropdown {
  display: block;
}

.profile-dropdown li {
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.nav-booking,
.nav-property {
  position: relative;
  display: block;
  z-index: 100;

  &:hover {
    .trips-dropdown {
      display: block;
    }
  }
}

.trips-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 75px;
  border: 1px solid #eee;
  z-index: 1000;

  .booking-last {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 50px;
  }
}

.trips-dropdown:empty {
  border: none;
}

.trips-dropdown li {
  width: 250px;
  height: 85px;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 15px 15px;
  transition: padding 0.5s;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.trips-dropdown li:hover {
  padding: 15px 15px 15px 20px;
}

.trips-dropdown li:last-child {}

.booking-dropdown-image {
  height: 60px;
  width: 60px;
}

.booking-city {
  font-weight: bold;
}

.property-name {
  font-weight: bold;
  text-align: left;
}

.booking-dates {
  font-size: 12px;
}

.nav-logout {
  margin-left: 0px;
  transition: margin-left 0.5s;
}

.nav-create-host {
  padding: 5px 5px;
  border: 2px solid #ccc;
}

.row.with-social {
  display: none;
}

.signup-modal-wrapper {
  width: 480px;
  padding: 50px;

  @media (max-width: $large-desktop-min) {
    width: 420px;
    padding: 40px;
  }

  @media (max-width: $large-mobile-min) {
    width: calc(100% - 90px);
    padding: 30px;
  }

  .signup-form-div {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    font-family: MaisonNeue-Book;
    font-size: 1.5em;

    .signup-form-box {
      width: 100%;

      .user-name {
        width: 100%;
      }

      .input-box {
        width: 100%;

        &--half {
          width: 48%;
        }
      }

      .input-box-error {
        color: #b30000;
        display: block;
        margin-top: 5px;
      }

      .input-item {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        justify-content: space-between;

        @media (max-width: $large-mobile-min) {
          flex-direction: row;
          margin-bottom: 10px;
        }

        input {
          width: 100%;
          height: 60px;
          background: $off-white;
          padding: 0 25px;

          @media (max-width: $large-desktop-min) {
            height: 55px;
          }

          @media (max-width: $large-mobile-min) {
            height: 50px;
            font-size: 12px;
            padding-left: 15px;
            padding-right: 15px;
          }

          &.first {
            margin-right: 15px;

            @media (max-width: $large-mobile-min) {
              margin-right: 10px;
            }
          }
        }
      }

      .user-submit {
        width: 100%;
        height: 60px;
        font-family: MaisonNeue-Demi;
        font-size: 0.93em;
        background-color: $custom-black;
        color: white;
        text-align: center;

        @media (max-width: $large-desktop-min) {
          height: 55px;
        }

        @media (max-width: $large-mobile-min) {
          height: 50px;
          font-size: 12px;
        }
      }
    }
  }

}

.login-modal-wrapper {
  width: 480px;
  padding: 50px;

  @media (max-width: $large-desktop-min) {
    width: 420px;
    padding: 40px;
  }

  @media (max-width: $large-mobile-min) {
    width: calc(100% - 90px);
    padding: 30px;
  }

  .login-form-div {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    font-family: MaisonNeue-Book;
    font-size: 1.5em;

    input {
      background-color: $off-white;
    }

    .login-form-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      &-error {
        color: #c90000;
        display: block;
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
      }

      .input-fields-box {
        width: 100%;

        .input-item {
          .login-input {
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            padding: 0 25px;

            @media (max-width: $large-desktop-min) {
              height: 55px;
            }

            @media (max-width: $large-mobile-min) {
              width: 100% !important;
              height: 50px;
              margin-bottom: 10px;
              font-size: 12px;
            }
          }
        }
      }

      .links {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: $medium-mobile-min) {
          margin-top: 10px;
        }

        a {
          text-decoration: none;
          color: #212121;
          padding-top: 30px;
          cursor: pointer;

          @media (max-width: $large-mobile-min) {
            font-size: 12px;
            padding-top: 10px;
          }
        }
      }

      .user-submit {
        width: 100%;
        height: 60px;
        padding: 0 10px;
        color: white;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.93em;

        @media (max-width: $large-desktop-min) {
          height: 55px;
        }

        &.main-login {
          background: #212121;

          &:hover {
            background: #4e4e4e;
          }

          @media (max-width: $large-mobile-min) {
            height: 50px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.google-link {
  width: 383px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $custom-black;
  background: $off-white;

  .google-logo {
    background-image: url(../assets/google_icon.png);
  }
}

.facebook-link {
  width: 383px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: $off-white;

  .facebook-logo {
    background-image: url(../assets/facebook_icon.png);
  }
}

.separator {
  display: flex;
  margin: 15px;
  font-size: 14px;
}

.separator-line {
  border-bottom: 1px solid gray;
}

.google-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 30px;
  width: 30px;
}

.facebook-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 30px;
  width: 30px;
}

.form-input:focus {
  box-shadow: 0px 0px 13px rgb(71, 152, 198);
}

.form-errors {
  list-style: none;
  padding-left: 10px;
}

.fa-paper-plane-o {
  font-size: 24px;
}

.nav-search {
  color: rgb(118, 118, 118);
  display: flex;
  justify-content: flex-start;
}

.fa-search {
  color: rgb(205, 205, 205);
  font-size: 30px;
  padding-right: 10px;
}

.ReactModal__Overlay {
  z-index: 10;
}

.user-submit:hover,
.google-link:hover {
  cursor: pointer;
}

.about-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-port {
  text-decoration: underline;
  color: rgb(15, 142, 176);
}

.about-port:hover {
  color: rgb(36, 102, 170);
}

.about-modal i {
  font-size: 35px;
}

.about-links {
  display: flex;
  justify-content: space-between;
  width: auto;
  margin-top: 20px;
}

.about-links a:hover {
  color: rgb(7, 164, 136);
}

.prof-image-text {
  margin: 5px 0 0 0;
  font-weight: normal;
}

.prof-image {
  width: 383px;
}

.nav-profile-pic {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.amenity-info-modal {
  .modal-header {
    h1 {
      font-size: 24px;
    }
  }

  .amenity-marker {
    margin-top: 30px;

    h2 {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .amenity-info-body {
    margin-top: 30px;

    h2 {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.nav-buttons {
  &.group.menus {
    flex: 100;
  }
}

@media (max-width: $large-mobile-max) {
  .navbar {
    height: auto;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: none;
    overflow: hidden;

    .nav-logo {
      width: 100%;
      margin-top: 22px;
      margin-bottom: 22px;
      background-position: center;
      margin-left: 0;
      padding-left: 20px;
      display: flex;
      position: relative;

      .logo-image {
        width: 100%;
      }

      .fa-bars {
        width: 25px;
        font-size: 27px;
        float: right;
        margin-right: 20px;
        display: block;
      }
    }

  .navbar-container {
    &.visible {
      flex-direction: column;
      position: fixed;
      top: 0;
      background: #141415 !important;
      z-index: 100;
      .menu-wrapper {
        display: flex;
        }
        .nav-logo {
          opacity: 1;
          .logo-image {
            background-image: url("../assets/svg/navlogo-white.svg");
          }
        }
      }
    &.invisible {
      .menu-wrapper {
        max-height: 0;
          ul {
            display: none;
          }
        }
      }
    }
    .menu-wrapper {
      flex-direction: column;
      background: $custom-black;
      width: 100%;
      transition: max-height .2s linear;
      z-index: 100;

      > * {
        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
        }
      }



      .nav-buttons-container {
        width: auto;
        justify-content: center;
      }

      .nav-buttons {
        flex-direction: column;
        align-items: center;
        width: 100%;
        display: block;

        li {
          color: white;
          padding-top: 20px;
          padding-bottom: 20px;
          width: 100%;
          font-size: 20px;
          cursor: pointer;
          height: auto;

          &:hover,
          &.nav-user-info,
          &.nav-signup,
          &.nav-login {
            background: transparent;
          }

          &.nav-login {
            span {
              display: block;
            }

            i {
              display: none;
            }
          }

          &.nav-user-info {
            padding-top: 0;
            padding-bottom: 0;

            i {
              display: none;
            }

            .profile-dropdown {
              display: block;
              position: relative;
              bottom: 0;
              border: none;

              li {
                background: transparent;
                height: auto;

                &.dropdown-logout:hover {
                  .nav-logout {
                    margin-left: 0;
                  }
                }
              }
            }
          }

          .trips-dropdown {
            display: none;
          }

          a {
            display: block;
            text-align: center;
          }
        }

        .nav-booking,
        .nav-property {
          background: transparent;
        }
      }
    }
  }
}

@media (max-width: 652px) {
  .navbar {
    .nav-buttons-container {
      width: 100%;
    }
  }
}

@media (max-width: 458px) {
  .navbar {
    .nav-buttons-container {
      ul li {
        width: 100px;
      }

      .nav-user-info {
        width: 80px;
      }
    }
  }
}
