.booking-form-container {
  background-color: white;
  color: black;
  margin-top: 88px;
  max-width: 700px;
  
  .form-container {
    margin: 0 auto;
    width: 620px;
    margin-bottom: 150px;

    &.rules,&.extras {
      width: 540px;
      margin-bottom: 85px;
      padding: 0;

      h3 {
        max-width: 480px;
        margin-bottom: 50px;
      }

      input {
        margin-bottom: auto;
        margin-top: 2px;
      }

      .rule {
        font-family: MaisonNeue-Book;
        font-size: 14px;
        line-height: 20px;
        text-align: justify;

        div {
          font-weight: bold;
        }
      }

      .large-margin {
        margin-top: 35px;
      }
    }

    &.payment {
      width: 630px;
      margin-bottom: 280px;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }

    .large-margin {
      margin-top: 60px;
    }

    h1 {
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 30px;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
      font-family: MaisonNeue-Demi;
      margin-bottom: 30px;
      text-transform: uppercase;
    }

    h3 {
      max-width: 460px;
      font-style: italic;
      font-size: 18px;
      font-weight: 500;
      font-family: Sentinel-BookItalic;
      margin-bottom: 60px;

      &.not-end {
        margin-bottom: 0;
        margin-top: 20px;
      }

      &.center {
        text-align: center;
      }
    }

    .title {
      width: 70px !important;
    }

    .extra-items-container {
      margin-top: 10px;

      .extra-item-box {
        display: flex;
        margin-bottom: 20px;
        font-family: MaisonNeue-Demi;
        font-size: 14px;
        align-items: center;

        .extra-title {
          text-transform: uppercase;
          flex: 1;
        }

        .extra-value {
          width: 100px;
          text-align: right;
        }

        .add-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 40px;
          background-color: #242424;
          color: white;
          cursor: pointer;
          margin-left: 90px;

          &:hover {
            background-color: #444444;
            color: white;
          }

          &.checked {
            background-color: #888888;
          }
        }
      }

      .extra-notify-items {
        margin-left: -8px;
        margin-top: 50px;

        .toggle-button {
          height: 24px;
          margin-bottom: 30px;

          .toggle-description {
            font-family: MaisonNeue-Book;
            font-size: 16px;
          }
        }
      }
    }

    .row-container {
      // height: 60px;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      color: black;

      div:not(:last-child) {
        margin-right: 15px !important;
      }      

      .input-render-wraper {
        input {
          background-color: #eeeeee;
        }
      }

      .phone-number-render-wraper {
        .react-phone-number-input {
          .react-phone-number-input__row {
            .react-phone-number-input__phone {
              background-color: #eeeeee;
            }
          }
        }
      }

      .dropdown-render-wraper {
        .dropdown-container {
          .ui.selection.dropdown {
            background-color: #eeeeee;

            .menu>.item {
              background-color: #eeeeee;
            }
          }
        }
      }

      &.center {
        display: flex;
        justify-content: center;
        height: auto;
      }

      .year, .month {
        width: 145px;
        color: black;
      }

      .cvv {
        width: 400px;
        color: black;
      }

      &.checkgroup-container {
        height: auto;
        margin-bottom: 20px;

        div {
          display: flex;
          align-items: center;

          label {
            margin-left: 20px;
          }
        }
      }

      .pillow-type {
        height: 100%;
        width: 100%;
        margin: 0 20px;
        background-color: #eeeeee;
        font-size: 16px;
      }

      .title {
        height: 100%;
        width: 12px;
        flex-grow: 1;
        background-color: #eeeeee;
        font-size: 16px;
        margin-right: 20px;
      }

      .separator {
        height: 1px;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: black;
        margin-top: 15px;
      }

      .firstname, .lastname {
        flex-grow: 2;
        width: 120px;
      }

      .firstname {
        margin-right: 20px;
      }

      .email, .card-number {
        height: 100%;
        width: 100%;
      }

      .carholder-name {
        width: 50%;
        color: black;
        
        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      &.phone-numbers-row {
        height: auto;

        .phone-numbers {
          display: flex;
          width: calc(100% - 100px);
          flex: 1;

          .phone-number-wraper {
            width: 100%;

            .react-phone-number-input {
              width: 100%;
              .react-phone-number-input__row {
                width: 100%;
              }
            }
          }

          > ul {
            width: 100%;

            > li {
              list-style: none;
              // height: 60px;
              
              &:not(:last-child) {
                margin-bottom: 20px;
              }
    
              .phone-number {    
                display: flex;
                width: 100%;
                background-color: white;
                font-size: 16px;
                color: rgb(110, 110, 110);
                margin-right: 20px;

                .react-phone-number-input {
                  height: 60px;
                }
              }
            }
          }

          .add-phone {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            width: 80px;
            background-color: #eeeeee;
            cursor: pointer;
    
            &:hover {
              background-color: #888888;
              color: white;
            }
          }
        }
      }

      .phone {
        flex: 2;
        height: 100%;
        background-color: #eeeeee;
        width: 20px;
        padding: 0 20px;
        margin-right: 20px;
      }

      .rule-item {
        width: 20px;
        background-color: white;
        border: none;
      }

      .rule {
        width: calc(100% - 30px);
        margin-left: 30px;
      }

      .property-address {
        height: 100%;
        width: 100%;
      }

      .submit {
        width: 100%;
        height: 60px;
        background-color: #141414;
        color: white;
        text-align: center;
        cursor: pointer;

        &.button-disable {
          background-color: #888888;
        }
        // &:hover {
        //   background-color: #888888;
        // }
      }
    }

    .booking-reason {
      margin-bottom: -2px;

      textarea {
        min-height: 120px;
        height: 120px;
        background-color: #eee !important; 
      }
    }
  }
}
