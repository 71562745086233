@import './mixins.scss';

.quarto-light {
  font-family: Quarto-Light;
}

.quarto-maison-demi {
  font-family: MaisonNeue-Demi;
}

.app-div {
  position: relative;

  @media (min-width: $large-desktop-min) {
    margin-top: 70px;
  }

  .ui.selection.dropdown {
    display: flex;
    align-items: center;
    min-width: auto;
    border: 1px solid transparent;
    box-shadow: 0 0 4px transparent;
    width: calc(100% - 50px);
    height: 100%;
    padding: 0 25px;
    border-radius: inherit;
    color: black;
    transition: all .1s ease-in-out;
  }

  .ui.active.selection.dropdown {
    border: none;

    &:hover {
      border: none;
    }

    .menu {
      border: none;
      min-width: 100%;
    }
  }

  .ui.selection.dropdown > .delete.icon,
  .ui.selection.dropdown > .dropdown.icon,
  .ui.selection.dropdown > .search.icon {
    height: 100%;
    display: flex;
    padding: 0 25px;
    align-items: center;
  }

  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: #888;
  }

  .ui.input {
    width: calc(100% - 40px);
    height: 100%;
    padding-left: 40px;
    border: none;
  }

  .ui.icon.input > i.icon {
    left: 0;
  }

  .ui.icon.input > i {
    font-size: 20px;
    font-weight: 300;
  }

  .ui.search .prompt {
    border-radius: 5px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .ui.input input {
    border: none;
  }

  .gm-style-iw {
    left: 0;
    top: 0;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 60px;
  }

  .react-phone-number-input {
    width: 100%;
    height: 100%;

    .react-phone-number-input__row {
      height: 100%;

      .react-phone-number-input__phone {
        height: 100%;
        padding-left: 20px;
        width: 65%;
        border: none;
        background-color: #eeeeee;
      }

      .react-phone-number-input__country {
        height: 100%;
        margin-right: 20px;
        background-color: #eeeeee;
        width: 125px;

        .rrui__input {
          height: 100%;
          border: none;

          .rrui__input-element {
            width: 100%;
            padding: 0 20px;

            .rrui__select__selected-content {
              height: 100%;

              .rrui__select__selected-label {
                height: 100%;
                display: flex;
                align-items: center;

                img {
                  height: 60%;
                  width: auto;
                }
              }
            }
          }
        }

        .rrui__select__arrow {
          width: 8px;
          height: 8px;
          color: #444444;
        }
      }
    }
  }
}

.crop-button {
  width: calc(100% - 50px);
  height: 50px;
  padding: 0 25px;
  border-radius: 2px;
  color: white;
  text-align: center;
  background: #212121;
  margin-top: 20px;
  cursor: pointer;
}

.creditcard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  font-family: 'MaisonNeue-Demi';

  .card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      height: 46px;
      margin-right: 10px;
      margin-left: -8px;
    }
  }

  .card-actions {
    .card-action {
      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }
}

.toggle-button {
  display: flex;
  align-items: center;
  height: 60px;

  .toggle-box {
    display: flex;

    .toggle-label {
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: bold;

      &.enable {
        color: #141315;
      }

      &.disable {
        color: #888;
      }
    }

    .ui.toggle.checkbox {
      width: 45px;

      label:before {
        width: 45px;
      }

      input {
        width: 32px;
        height: 24px;

        &:checked ~ label:before {
          background-color: #141315 !important;
        }

        &:checked ~ label:after {
          left: 22px;
        }

        &:before {
          background-color: #eeeeee !important;
        }
      }
    }
  }

  .toggle-description {
    margin-left: 32px;
    margin-top: 3px;
    font-size: 16px;
    font-family: MaisonNeue-Book;
    color: #141414;
  }
}

// Slider component
.slick-arrow {
  z-index: 1;

  &.slick-prev {
    transform: translate(0, -50%) rotate(180deg);
  }

  &.slick-prev,
  &.slick-next {
    width: 28px;
    height: 28px;
  }
}

// Underlined texts
.underlined {
  position: relative;
  padding-bottom: 50px;

  &::after {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    content: '';
    width: 80px;
    height: 2px;
    background-color: #141414;
  }
}

// Italic font-style
.italic {
  font-style: italic;
}

// Mobile visibility
.hidden-sm {
  display: block;
}

.visible-sm {
  display: none;
}

@media (max-width: $large-mobile-min) {
  .hidden-sm {
    display: none;
  }

  .visible-sm {
    display: block;

  }
}

//TODO: refactor all content and split to separate files
// Header with bg image styles
.header-page {
  position: relative;
  display: flex;
  align-items: center;
  height: ($R * 66.6vw);
  margin: 0;
  padding: 70px 0;
  box-sizing: border-box;
  background-size: cover;

  @media (max-width: $large-mobile-min) {
    margin: 0;
    background-position: 30%;
    min-height: 180px;
  }

  h2 {
    width: 25%;
    margin-left: 12.1%;
    color: #fff;
    font-family: Quarto-Light;
    font-weight: 200;
    font-size: 10em;
    line-height: 1;

    @media (max-width: $large-desktop-min) {
      width: auto;
      align-self: flex-start;
      font-size: 55px;
    }

    @media (max-width: $large-mobile-min) {
      font-size: 45px;
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 80px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-family: MaisonNeue-Demi;
    font-size: 1.6em;
    text-align: center;
    text-transform: uppercase;
    background: #cdccca;
    cursor: pointer;
    color: #080808;

    &:hover {
      color: inherit;
    }

    @media (max-width: $large-desktop-min) {
      height: 60px;
    }

    @media (max-width: $large-mobile-min) {
      width: 169px;
    }

    .longer-text {
      padding-left: 2px;

      @media (max-width: $large-mobile-max) {
        display: none;
      }
    }
  }

  .row-container {
    width: 100%;
  }
}

.page-title {
  font-size: 55px;
  line-height: 0.909;
  color: white;
  text-align: center;
}

a {
  transition: opacity .2s;
  backface-visibility: hidden;

  &:hover {
    opacity: .8;
  }
}

button:not(.read-case),
main a {
  transition: background .2s;

  &:hover {
    background: #dcdcdc !important;
    opacity: 1;
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.imagePlaceholder {
  position: absolute;
  background: #000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.loadedImage-enter {
  opacity: 0.01;
}

.loadedImage-enter.loadedImage-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.loadedImage-leave {
  opacity: 1;
}

.loadedImage-leave.loadedImage-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
